.info-component {
  display:grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas:
          "left-gutter info-component__wrapper right-gutter";

  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;
  padding: 2rem 0;

  &__wrapper {
    grid-area: info-component__wrapper;
    -ms-grid-column: 2;
  }

  &__image {
    -ms-grid-column: 1;
    margin-bottom: 2rem;
  }

  &__text {
    -ms-grid-column: 3;
    @include fluid-type($min_width, $max_width, (18px/1.25), 18px, (1.6/1.2), 1.6);
  }
}

@include media-breakpoint-up(md) {
  .info-component {
    grid-template-columns: 2fr 12fr 2fr;
    -ms-grid-columns: 2fr 12fr 2fr;
    padding: 4rem 0;

    &__wrapper {
      display:grid;
      grid-template-columns: 6fr 6fr;
     grid-gap: 2rem;

      display: -ms-grid;
      -ms-grid-columns: 6fr 2rem 6fr;
    }

    &__image {
      margin-bottom: 0;
    }
  }
}
.text-component {
  display: grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas: "left-gutter text-component__body right-gutter";

  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;
  padding: 2rem 0;

  &__body {
    padding-bottom: 1rem;

    grid-area: text-component__body;
    -ms-grid-column: 2;
    text-align: center;

    form {
      margin-top: 3rem;
      .input-wrapper {
        * {
          text-align: center;
        }
        .btn {
          margin-top: 2rem;
          align-self: center;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .text-component {
    grid-template-columns: 3fr 8fr 3fr;
    -ms-grid-columns: 4fr 6fr 4fr;
    padding: 7rem 0;
  }
}

@include media-breakpoint-up(xl) {
  .text-component {
    grid-template-columns: 4fr 6fr 4fr;
    -ms-grid-columns: 4fr 6fr 4fr;
    padding: 7rem 0;
  }
}
@import "maps-marker-cluster";

$filter-main-color: $primary;

.maps-header {
  background-color: $white;
  height: 50px;
  display: flex;
  align-items: center;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.maps-logo {
  display: flex;
  margin: 0 1rem;
  img {
    max-height: 36px;
    transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  }
}

.maps-container {
  display: flex;
  //height: calc(100vh - 50px);
  height: calc(100vh - 50px); /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100 - 50px);
  width: 100vw;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  &[data-show-filters="true"] {
    .maps-wrapper {
      width: calc(100% - 80vw);
    }
    .maps-filters-wrapper {
      right: 0;
    }
  }
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.leaflet-pane {
  transition: 0.3s;
}

.maps-wrapper {
  width: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.interactive-map {
  height: 100%;
  width: 100%;
}

.maps-marker {
  cursor: pointer;
}

.maps-filters-wrapper {
  position: fixed;
  top: 50px;
  right: -80vw;
  width: 80vw;
  //height: calc(100vh - 50px);
  height: calc(100vh - 50px); /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100 - 50px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);

  .maps-filters {
    background-color: darken($filter-main-color, 5%);
    height: 100%;
    overflow-y: auto;

    ul {
      li {
        overflow: hidden;
        transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
        .data-collapse {
          position: relative;
          + ul {
            visibility: hidden;
            max-height: 0;
            transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
          }
        }

        .data-collapse[data-collapse="true"] {
          .toggle-icon {
            transform: rotate(135deg);
          }
          + ul {
            visibility: visible;
            //max-height: calc(100vh - 70px);
            max-height: calc(100vh - 70px); /* Fallback for browsers that do not support Custom Properties */
            max-height: calc(var(--vh, 1vh) * 100 - 70px);
          }
        }
      }
    }

    > ul {
      list-style: none;
      padding: 0;
      margin: 0;

      > li {
        @extend %advent-pro;
        @include fluid-type($min_width, $max_width, (30px/2), 30px, 1.85, 1.85);
        font-weight: normal;
        text-transform: uppercase;
        color: $body-color;
        @extend .noselect;

        .data-collapse[data-collapse="true"] {
          background-color: darken($filter-main-color, 5%);
          color: $white;
        }

        &:not(:first-child) {
          border-top: 1px solid darken($filter-main-color, 5%);
        }

        .categorie-link {
          position: relative;
          display: flex;
          align-items: center;
          padding: .5rem 1rem;
          cursor: pointer;

          .toggle-icon {
            margin-right: 2px;
            margin-left: auto;
            width: 15px;
            height: 15px;
            min-width: 15px;
            border-bottom: 2px solid #fff;
            border-left: 2px solid #fff;
            transform: rotate(-45deg);
            display: inline-block;
            transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
            transform-origin: center;
          }

          &:hover {
            background-color: darken($filter-main-color, 5%);
            color: $white;
          }
        }

        .category-icon {
          padding: 0;
          margin-right: 0.75rem;
          display: inline-block;
          width: 24px;
          min-width: 24px;

          img {
            display: block;
            width: 100%;
          }
        }

        > ul {
          background-color: darken($filter-main-color, 5%);
          list-style: none;
          padding: 0;
          margin: 0;
          transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;

          > li {
            @extend %dagny-pro;
            @include fluid-type($min_width, $max_width, (20px/1.4), 20px, 1.4/1.2, 1.4);
            text-transform: none;
            color: $white;

            .data-collapse[data-collapse="true"] {
              background-color: darken($filter-main-color, 10%);
              color: $white;
            }

            &:not(:first-child) {
              border-top: 1px solid darken($filter-main-color, 10%);
            }

            .categorie-link {
              padding: .75rem 1rem;
              &:hover {
                background-color: darken($filter-main-color, 10%);
                color: $white;
              }
            }

            > ul {
              background-color: darken($filter-main-color, 10%);
              padding: 0;

              > li {
                .categorie-link {
                  padding: .75rem 1rem .75rem 2rem;
                  &:hover {
                    background-color: darken($filter-main-color, 15%);
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .maps-results {
    padding: 1rem;
    background-color: darken($filter-main-color, 5%);
    &__label {
      padding: 1.5rem 2rem;
      @extend %dagny-pro;
      @include fluid-type($min_width, $max_width, (20px/1.4), 20px, (1.2), 1.2);
      color: $white;
      font-weight: 700;
      text-align: center;
      display: none;
    }
    &__btn {
      width: 100%;
      text-align: center;
    }
  }
}

.categorie-link {
  &[data-select] {

    //select outer element
    &:before {
      position: absolute;
      right: 1rem;
      content: "";
      width: 12px;
      height: 6px;
      margin-left: auto;
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
      transform: rotate(-25deg) translateY(-3px) translateX(-2px);
      transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
      opacity: 0;
    }

    //select inner element
    &:after {
      content: "";
      width: 20px;
      min-width: 20px;
      height: 20px;
      border: 2px solid rgba($white,0.5);
      float: right;
      margin-left: auto;
    }

  }

  &[data-select="true"] {
    &:before {
      transform: rotate(-45deg) translateY(-3px) translateX(-2px);
      opacity: 1;
    }
    &:after {
      border: 2px solid rgba($white,1);
      float: right;
    }
  }
}

.maps-filters-toggle {
  background: $filter-main-color;
  width: 40px;
  height: 40px;
  position: absolute;
  left: -40px;
  z-index: 999;
  top: 0;
  cursor: pointer;
}

.maps-filters-overlay {
  width: calc(100vw + 300px);
  height: 100%;
  position: absolute;
  right: 0;
  z-index: -1;
  top: 0;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.toggle-filters-icon {
  span {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 6px;
    height: 6px;
    background-color: $white;
    margin-left: -2px;
    margin-top: 7px;

    &:after {
      display: block;
      content: "";
      width: 6px;
      height: 100%;
      background-color: $white;
      right: -10px;
      position: absolute;
    }

    &:before {
      display: block;
      content: "";
      width: 6px;
      height: 100%;
      background-color: $white;
      left: -10px;
      position: absolute;
    }

    &:nth-of-type(2){
      top: 10px;
    }

    &:nth-of-type(3){
      top: 20px;
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

//leaflet popup styling
.leaflet-popup-content-wrapper {
  padding: 0;
  border-radius: 0;
}

.leaflet-popup-content {
  margin: 0;
}

.leaflet-container a.leaflet-popup-close-button {

  width: 24px;
  height: 24px;
  padding: 0;
  font: 24px/22px Tahoma, Verdana, sans-serif;
  color: $white;
  transition: 0.4s;
  z-index: 0;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    background: $filter-main-color;
    z-index: -1;
    transition: 0.4s;
  }

  &:hover {
    color: $white;
    transform: rotate(45deg);
    &:after {
      transform: rotate(-45deg);
    }
  }
}

.popup-info {
  width: 100%;
  img {
    width: 100%;
  }
  &__body {
    padding: 1rem;
    @extend %dagny-pro;
    @include fluid-type($min_width, $max_width, (18px/1.2), 18px, 1.4/1.2, 1.4);
    color: $body-color;

    a {
      color: $filter-main-color;
      text-decoration: none;
      border-bottom: 1px solid;
    }
  }
  &__title {
    margin-bottom: 1rem;
    @extend %advent-pro;
    @include fluid-type($min_width, $max_width, (30px/1.6), 30px, 1, 1);
  }
}

@include media-breakpoint-only(xs) {
  .leaflet-popup-content {
    width: 250px!important;
    min-width: auto!important;

    p {
      margin: 0 0 0.5rem 0;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  .maps-container {
    &[data-show-filters="true"] {
      .maps-filters-wrapper {
        .maps-filters-overlay {
          background: rgba($black,.5);
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .maps-logo {
    img {
      max-height: 50px;
    }
  }
  .maps-header {
    height: 70px;
  }
  .maps-container {
    //height: calc(100vh - 70px);
    height: calc(100vh - 70px); /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100 - 70px);
    &[data-show-filters="true"] {
      .maps-wrapper {
        width: calc(100% - 300px);
      }
    }
  }
  .maps-filters-wrapper {
    top: 70px;
    //height: calc(100vh - 70px);
    height: calc(100vh - 70px); /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100 - 70px);
    right: -300px;
    width: 300px;

    .maps-results {
      background-color: $secondary;
      &__label {
        padding: 0.5rem 1rem;
        display: block;
      }
      &__btn {
        display: none;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .maps-filters-wrapper {
    .maps-results {
      &__label {
        padding: 0.5rem 2rem;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .maps-container {
    &[data-show-filters="true"] {
      .maps-wrapper {
        width: calc(100% - 400px);
      }
    }
  }
  .maps-filters-wrapper {
    right: -400px;
    width: 400px;
  }
}
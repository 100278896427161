nav {
    ul.pagination {
        margin: 1.5rem 0;
    }
    li.page-item {
        margin: 0 0.25rem;
        a {
            line-height: 19px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
        }
        &.previous, &.next {
            margin: 0 1rem;
        }
        &.previous {
            transform: rotate(-180deg);
        }
        &.next {
            //transform: rotate(225deg);
        }
        a.page-link {
            height: 38px;
            width: 38px;

            border-radius: 0px!important;
            position: relative;
            vertical-align: middle;
        }
        &.disabled {
            a.page-link {
                .custom-arrow {
                    &:after {
                        background-color: $white;
                    }
                    &:before {
                        background-color: $gray-300;
                    }
                }
            }
        }
    }
}

//custom arrow
.custom-arrow {
    position: relative;
    display: flex;
    min-width: 10px;
    align-items: center;
    justify-content: center;

    &:before {
        @extend .visitheerde-icons;
        @extend .visitheerde-icons-arrow-next;
        font-size: 9px;
        text-align: center;
        width: 10px;
    }
}

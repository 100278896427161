.main-wrapper {
  display:grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas:
          "left-gutter breadcrumb right-gutter"
          "left-gutter share right-gutter";

  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;
}
.breadcrumb {
  grid-column: breadcrumb;
  -ms-grid-column: 2;
  padding: .5rem 0;

  li {
    @extend .breadcrumb-item;

    a {
      //@include fluid-type($min_width, $max_width, (18px/1.5), 18px, (1.5/1.25), 1.5);
      color: inherit;
      text-decoration: none;
    }

    &.last {
      @extend .active;
    }
  }
}

@include media-breakpoint-up(md) {
  .main-wrapper {
    grid-template-columns: 2fr 6fr 6fr 2fr;
    grid-template-areas:
            "left-gutter breadcrumb share right-gutter";

    -ms-grid-columns: 2fr 6fr 6fr 2fr;
  }
  .breadcrumb {
    padding: 1.5rem 0;
  }
}

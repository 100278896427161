.follow-us {
  padding: 1.5rem 0;

  .share__icons {
    padding: .5rem 0 .75rem;
    //text-align: center;
  }
}

.share {
  display: flex;
  justify-content: center;
  //align-items: center;
  flex-direction: column;

  .share__title {
    position: relative;
    padding: $spacer 0;

    @extend %advent-pro;
    @include fluid-type($min_width, $max_width, (40px/1.6), 40px, 1.4/1.6, 1.4);
    color: $white;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;

    li {
      display: inline;
      padding: 0;
      text-align: center;

      &:not(:last-child) {
        margin-right: 0.25rem;
      }

      a {
        color: $white;
        text-decoration: none;
        transition: .4s cubic-bezier(.49,1,.22,1) 0ms;

        i {
          background-color: transparent;
          margin-right: 0.5rem;

          width: 32px;
          height: 32px;
          @include fluid-type($min_width, $max_width, (32px/1.3), 32px, 1, 1);
          text-align: center;
        }

        &:hover {
          color: $primary;
        }
      }
    }
  }
  &--page {
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    grid-column: share;
    -ms-grid-column: 2;
    -ms-grid-row: 3;
    padding: .5rem 0;

    .share__title {
      margin-right: 1rem;
      @include fluid-type($min_width, $max_width, (16px/1.6), 16px, 1.4/1.6, 1.4);
      color: $body-color;
      text-transform: none;
    }
    ul {
      margin: 0;
      li {
        &:not(:last-child) {
          margin-right: 0.5rem;
        }
        a {
          color: $body-color;

          i {
            width: 22px;
            height: 22px;
            @include fluid-type($min_width, $max_width, (20px/1.3), 20px, 1.6, 1.6);
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .share {
    ul {
      //margin: 0 0 0 2.5rem;

      li {
        &:not(:last-child) {
          margin-right: 2.5rem;
        }
      }
    }
    &--page {
      padding: 1.5rem 0;
      -ms-grid-column: 3;
      -ms-grid-row: 1;

      ul {
        margin: 0;
        li {
          &:not(:last-child) {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}

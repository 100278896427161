.form {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 2rem;

  &__header {
    background-color: #f6f6f6;
    display: flex;
    box-shadow: 0 24px 32px -8px rgba(49, 39, 131, 0.15);

    img {
      width: 100%;
    }
  }

  &__content {
    background-color: #ffffff;
    padding: 1.5rem;
    box-shadow: 0 24px 32px -8px rgba(49, 39, 131, 0.15);
  }

  &__header__content {
    padding: 1.5rem;
    flex: 4;
  }

  &__header__image {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 2;
  }
}

input[type=text], input[type=number], input[type=password], input[type=email], select {
  width: 100%;
  line-height: 1.5;
  border: none;
  border-bottom: 4px solid $grey-dark;
  @extend %dagny-pro;
  @include fluid-type($min_width, $max_width, (24px/1.2), 24px, (1.4/1.2), 1.4);
  color: $body-color;
  padding: 1rem 2rem 1rem 2rem;
  outline: none;
  transition: 0.3s;
  order: 2;

  &:focus {
    border-color: $primary;
  }
}

.input-wrapper {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;

  .btn {
    margin-bottom: 0;
    width: 100%;
    align-self: flex-start;
  }

  label {
    font-weight: bold;
    text-transform: uppercase;
  }
}

textarea {
  width: 100%;
  line-height: 1.5;
  border: none;
  border-bottom: 4px solid $grey-dark;
  @extend %dagny-pro;
  @include fluid-type($min_width, $max_width, (24px/1.2), 24px, (1.4/1.2), 1.4);
  color: $body-color;
  padding: 1rem 2rem 1rem 2rem;
  outline: none;
  overflow: hidden;
  resize: none;
  transition: 0.3s;
  order: 2;

  &:focus {
    border-color: $primary;
  }
}

input[type=submit], button[type=submit] {
  border: none;
  cursor: pointer;
}

::-webkit-input-placeholder { /* Edge */
  color: #282828!important;
  font-style: italic!important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #282828!important;
  font-style: italic!important;
}

::placeholder {
  color: #282828!important;
  font-style: italic!important;
}

//// checkbox styling
.checkbox-wrapper {

}
// checkbox label
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
}

// checkbox
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  top: 4px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid $grey;
  border-radius: 3px;
  transition: 0.3s;
}

// checkbox checked
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  width: 10px;
  height: 16px;
  transition: .3s;
  -webkit-transform: rotateZ(90deg) scale(0);
  transform: rotateZ(90deg) scale(0);
  border-bottom: 4px solid $primary;
  border-right: 4px solid $primary;
  top: -2px;
  left: 2px;
}

[type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: rotateZ(45deg) scale(1);
  transform: rotateZ(45deg) scale(1);
  top: 0px;
  left: 7px;

}
// checkbox disabled
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  background-color: #f9f9f9;
}
[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
[type="checkbox"]:disabled + label {
  color: #aaa;
}

// checkbox focus
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
  border-color: $primary;
  border-radius: 3px;
}

// checkbox hover
[type="checkbox"]:checked:hover + label,
[type="checkbox"]:not(:checked):hover + label {
  color: $primary;
  border-radius: 3px;
}

//// radio button styling
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
  position: relative;
  padding-left: 28px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid $grey;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: $primary;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.is-invalid {
  border-color: $red!important;
  ~ .invalid-text {
    display: block;
  }
}

.invalid-text {
  display: none;
  margin-top: 0.5rem;
  @include fluid-type($min_width, $max_width, (17px/1.2), 17px, (1.4/1.2), 1.4);
  color: $red;
  order: 3;
}

//old validation
.formError {
  position: relative!important;
  left: 0!important;
  top: 0!important;
  margin-top: 0.5rem!important;
  opacity: 1!important;
  order: 3;

  .formErrorContent {
    background: transparent!important;
    border-radius: 0!important;
    color: $red!important;
    @include fluid-type($min_width, $max_width, (17px/1.2), 17px, (1.4/1.2), 1.4);
    font-weight: 500!important;
    padding: 4px 0 4px 0!important;
    box-shadow: none!important;
    -moz-box-shadow: none!important;
    -webkit-box-shadow: none!important;
    -o-box-shadow: none!important;
    border: none!important;
  }
  .formErrorArrow {
    * {
      display: none!important;
    }
  }
}

.select-wrapper {
  position: relative;
  justify-content: center;

  &:after {
    content: "";
    display: inline-block;
    margin-left: 10px;
    width: 10px;
    height: 10px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #313131;
    transform: rotate(90deg);
    position: absolute;
    right: 1rem;
    bottom: 1.75rem;
    @include transition(all .3s ease);
  }

  select {
    outline: none;
    border: none;
    border-bottom: 4px solid #333;
    cursor: pointer;
    transition: 0.3s;

    //hide default arrow
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;       /* Remove default arrow */
    background-image: url(...);   /* Add custom arrow */

  }
}

@include media-breakpoint-up(md) {
  .form {
    margin-left: 1.5rem;
    margin-right: 1.5rem;

    &__header__content {
      padding: 2.5rem;
    }

    &__content {
      padding: 2.5rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .form {
    margin-left: 0;
    margin-right: 0;
    max-width: 80%;
  }
}
aside {
  .sidebar-item {
    margin-bottom: 1.25rem;
  }
}

.contact-info {
  border-right: 1px solid;
  border-bottom: 1px solid;
  text-align: right;
  padding: 0 1.875rem 1.875rem 0;

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem;
    width: 110px;
    height: 110px;
    border: 2px solid $grey;
    border-radius: 50%;
    img {
      width: 100%;
      height: auto;
    }
  }

  a {
    color: $primary;
    text-decoration: none;
    border-bottom: 1px solid;
  }
}
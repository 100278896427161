.intro-component {
  display:grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
          "left-gutter intro-component__title right-gutter"
          "left-gutter intro-component__text right-gutter";
  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;
  -ms-grid-rows: auto 1fr;
  padding: 2rem 0;

  &__title {
    padding-bottom: 1rem;
    @include fluid-type($min_width, $max_width, (90px/1.5), 90px, 1, 1);
    font-weight: normal;
    text-transform: none;
    color: $white;
    grid-area: intro-component__title;
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-column-span: 3;

    h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
      margin-bottom: 0;
    }
  }

  &__text {
    grid-area: intro-component__text;
    -ms-grid-column: 2;
    -ms-grid-row: 2;
    -ms-grid-column-span: 1;
    color: $white;
  }

  &__footer {
    text-align: center;
  }
}

@include media-breakpoint-up(md) {
  .intro-component {
    grid-template-columns: 2fr 12fr 2fr;
    -ms-grid-columns: 2fr 12fr 2fr;
    padding: 4rem 0;
    &__body {
      column-count: 2;
     grid-gap: 4rem;
      padding-bottom: 3rem;
    }
  }
}
.search-result {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__body {

  }

  &__image {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;

    .image-large {
      transition: transform 0.2s;
      backface-visibility: hidden;
    }
  }

  &__content {
    margin-bottom: 2rem;

    p {
      margin-bottom: 0;
    }
  }

  span {
    position: relative;
    @include fluid-type($min_width, $max_width, (18px/1.2), 18px, 1.4/1.2, 1.4);
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    &:after {
      content: "\E001";
      position: absolute;
      top: calc(50% - 4px);
      margin-left: 0.875rem;
      font-family: "visitheerde-icons";
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      @include fluid-type($min_width, $max_width, (10px/1.25), 10px, 1, 1);
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
      text-decoration: none;
      text-transform: none;
      opacity: 0;
      transition: .35s cubic-bezier(.49,1,.22,1) 0ms;
    }
  }
}

a.search-result {
  color: $body-color;
  text-decoration: none;

  span {
    color: $primary;
    text-decoration: none;
    border-bottom: 1px solid;
  }

  &:hover {
    .search-result__image {
      .image-large {
        transform: scale(1.03);
      }
    }
    span {
      color: darken($primary,10%);
      &:after {
        opacity: 1;
        transform: translateX(0.5rem);
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .search-result {
    flex-direction: row;
    &__image {
      margin-right: 2rem;
      width: 20%;
    }
    &__content {
      margin-top: 2rem;
      width: 80%;
    }
  }
}

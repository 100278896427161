footer {
  background: $grey-dark url("../images/bg-footer.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 80%;
  display:grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas:
          "left-gutter footer-wrapper right-gutter";

  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;

  padding: 2rem 0;
}

.footer-wrapper {
  grid-area: footer-wrapper;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
}

.footer-block {
  margin-bottom: 2rem;
  text-align: center;

  &:nth-of-type(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-column-span: 1;
  }

  &:nth-of-type(2) {
    -ms-grid-column: 3;
    -ms-grid-row: 1;
    -ms-grid-column-span: 1;
  }

  &:nth-of-type(3) {
    -ms-grid-column: 1;
    -ms-grid-row: 2;
    -ms-grid-column-span: 1;
  }

  &:nth-of-type(4) {
    -ms-grid-column: 3;
    -ms-grid-row: 2;
    -ms-grid-column-span: 1;
  }

  @include fluid-type($min_width, $max_width, (23px/1.25), 23px, (1.7/1.25), 1.7);
  color: $white;

  a {
    color: $white;
    text-decoration: none;
    transition: 0.3s;

    &.highlighted {
      color: $primary;
    }

    &:hover {
      color: $primary;
    }
  }

  .navbar-brand {
    img {
      max-width: 58px;
    }
  }
}

.footer-logo {
  text-align: center;

  &__image {
    font-size: 0;
    line-height: inherit;
    img {
      max-width: 58px;
    }
  }

  &__content {
    @include fluid-type($min_width, $max_width, (16px/1.5), 16px, (1.6/1.5), 1.6);
    font-weight: bold;
    text-transform: uppercase;
  }
}

@include media-breakpoint-up(sm) {
  footer {
    background-size: 60%;
  }

  .footer-wrapper {
    display:grid;
    grid-template-columns: 2fr 2fr;
   grid-gap: 2rem;

    display: -ms-grid;
    -ms-grid-columns: 2fr 2rem 2fr;
  }
}

@include media-breakpoint-up(md) {
  footer {
    background-size: 40%;
    grid-template-columns: 1fr 12fr 1fr;
    padding: 4rem 0;
    -ms-grid-columns: 1fr 12fr 1fr;
  }

  .footer-wrapper {
      grid-template-columns: 2fr 2fr 2fr 4fr;
     grid-gap: 4rem;

      display: -ms-grid;
      -ms-grid-columns: 2fr 4rem 2fr 4rem 2fr 4rem 4fr;
  }

  .footer-block {
    margin-bottom: 0;
    text-align: inherit;

    &:nth-of-type(1) {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }

    &:nth-of-type(2) {
      -ms-grid-column: 3;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }

    &:nth-of-type(3) {
      -ms-grid-column: 5;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }

    &:nth-of-type(4) {
      -ms-grid-column: 7;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }
  }
}
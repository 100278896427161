.audience-component {
  display:grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
          "left-gutter audience-component__title right-gutter"
          "left-gutter audience-component__items right-gutter";
  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;
  -ms-grid-rows: auto 1fr;
  padding: 2rem 0;

  &__items {
    grid-area: audience-component__items;
    -ms-grid-column: 2;
    -ms-grid-row: 2;
    -ms-grid-column-span: 1;
  }
}

.audience {
  margin-bottom: 2rem;

  &:nth-of-type(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 3;
    -ms-grid-column-span: 1;
  }

  &:nth-of-type(2) {
    -ms-grid-column: 3;
    -ms-grid-row: 3;
    -ms-grid-column-span: 1;
  }

  &:nth-of-type(3) {
    -ms-grid-column: 5;
    -ms-grid-row: 3;
    -ms-grid-column-span: 1;
  }

  a {
    color: $body-color;
    text-decoration: none;
  }

  &__image {
    position: relative;
    width: 96px;
    height: 96px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1.25rem auto;
    z-index: 0;

    @extend .visitheerde-icons;
    @extend .visitheerde-icons-arrow-next;

    &:before {
      position: absolute;
      top: 0;
      width: 60px;
      height: 60px;
      left: 0;
      z-index: -1;
      color: $white;
      line-height: 60px;
      opacity: 0;

      -webkit-transform: translateX(25px);
      transform: translateX(25px);
      transition: .7s cubic-bezier(.50,1,.25,1) 0ms;
      will-change: transform;
    }

    &:after {
      content: "";
      background-color: $primary;
      position: absolute;
      top: 0;
      left: 50%;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      z-index: -2;
      transform: scale(0) translateX(-30px);
      transition: .4s 0.15s cubic-bezier(.49,1,.22,1);
    }

    img {
      max-height: 88px;
      -webkit-transform: none;
      transform: none;
      transition: .7s cubic-bezier(.50,1,.25,1) 0ms;
      will-change: transform;
    }
  }

  &__title {
    @extend %advent-pro;
    @include fluid-type($min_width, $max_width, (50px/1.6), 50px, 1.6/1.6, 1.1);
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
    transition: .4s cubic-bezier(.49,1,.22,1) 0ms;

    &:after {
      content: "~";
      display: block;
      font-weight: 700;
      line-height: 0.5;
    }
  }

  &__content {
    margin-bottom: 2.5rem;
    text-align: center;
    p {
      margin-bottom: 0;
      @include limit-lines($min_width, $max_width, (20px/1.2), 20px, 1.4/1.2, 1.4, 3);
    }
  }

  a:hover {
    .audience__image {
      &:before {
        -webkit-transform: translateX(42px);
        transform: translateX(42px);
        transition: .7s cubic-bezier(.50,1,.25,1) 0ms;
        will-change: transform;
        opacity: 1;
      }
      &:after {
        transform: scale(1) translateX(-30px);
      }
      img {
        -webkit-transform: translateX(65%);
        transform: translateX(65%);
        transition: .7s cubic-bezier(.50,1,.25,1) 0ms;
        will-change: transform;
      }
    }

    .audience__title {
      color: $primary;
    }
  }
}

@include media-breakpoint-up(md) {
  .audience-component {
    padding: 7rem 0;

    &__items {
      display:grid;
      grid-template-columns: 4fr 4fr 4fr;
     grid-gap: 4rem;

      display: -ms-grid;
      -ms-grid-columns: 4fr 4rem 4fr 4rem 4fr;
      -ms-grid-rows: 1fr;
    }
  }

  .audience {
    margin-bottom: 0;
  }
}

@include media-breakpoint-up(xxxl) {
  .audience-component {
    grid-template-columns: 2fr 12fr 2fr;
    -ms-grid-columns: 2fr 12fr 2fr;
  }
}
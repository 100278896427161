$palettes: (

        green: (
                base: #a5cd39
        ),
        red: (
                base: #d9534f
        ),
        gray: (
                light: #f8f9fa,
                dark: #343a40
        )

);
$red:           #c30c26;
$green:         #53bd8c;
$grey:          #e5e5e5;
$grey-light:    #909090;
$grey-dark:     #333333;
$grey-darker:   #0d0d0d;

$primary:       $green;

$secondary:     $grey-dark;

$light:         palette(gray, light);
$dark:          palette(gray, dark);

$white:    #fff;
$black:    #000;

$body-color:    #24272a;

$theme-colors: () !default;
$theme-colors: map-merge((
        "dunique-green":    palette(green, base)
), $theme-colors);


// Set a specific jump point for requesting color jumps
$theme-color-interval:      8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $black;
$yiq-text-light:            $white;
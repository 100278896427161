.gallery {
    @include clearfix;
    overflow: hidden;

    a {
        display: inline-block;
        float: left;
        position: relative;
        width: 20%;
        text-decoration: none;
        border-bottom: none!important;

        @extend .visitheerde-icons;
        @extend .visitheerde-icons-search;

        &:before {
            //content: "\E001";
            //font-family: "visitheerde-icons";
            //font-size: calculateRem(45px);
            //font-style: normal;
            //font-weight: normal;
            //font-variant: normal;
            //line-height: 1;
            //text-decoration: inherit;
            //text-rendering: optimizeLegibility;
            //text-transform: none;
            //-moz-osx-font-smoothing: grayscale;
            //-webkit-font-smoothing: antialiased;
            //
            //font-smoothing: antialiased;
            background-color: rgba($primary, 0.75);
            position: absolute;
            top: 0;
            left: 0;
            height:100%;
            width: 100%;

            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;

            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;

            -webkit-transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);
            transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);
            z-index: 1;
            opacity: 0;
        }

        &:hover {
            &:before {
                @include fluid-type($min_width, $max_width, (28px/1.2), 28px, 1.4/1.2, 1.4);
                opacity: 1;
            }
        }

        &:focus {
            outline: none;
        }
    }
}

.gallery {
    &.one-image {
        a {
            width: 100%;
        }
    }
    &.two-image {
        a {
            width: 50%;
        }
    }
    &.three-image {
        a {
            width: 50%;
        }
    }
    &.more {
        a {
            width: 50%;
        }
    }
}
.cta {
  position: relative;
  display: block;
  margin-bottom: 1rem;

  &__image {
    .image-large {
      transition: .7s cubic-bezier(.49, 1, .22, 1) 0ms;
    }
  }
  &__text {
    padding: 2rem;
    border: 1px solid $grey-darker;
  }

  &__title {
    position: relative;
    margin-bottom: 0.5rem;
    @extend %advent-pro;
    @include fluid-type($min_width, $max_width, (40px/1.6), 40px, 1, 1);
    font-weight: normal;
    text-transform: uppercase;
  }

  &__subtitle {
    font-weight: 700;
    text-transform: uppercase;
  }
}

a.cta {
  color: $body-color;
  text-decoration: none;
  &:hover {
    .cta__image {
      .image-large {
        transform: scale(1.05);
      }
    }
  }
}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(xl) {

}
$bg-color-menu: $green;
$bg-color-parent: $white;
$bg-color-children: $white;
$text-color: $white;

.hamburger {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  -ms-grid-column: 2;
  text-align: right;
}

.navbar-toggler {
  background-color: transparent;
  margin-top: 0;
  margin-right: 0;
  padding: 0;
  height: 48px;
  width: 48px;
  border: 0;
  cursor: pointer;
  outline: none;

  .navbar-toggler-icon {
    background-image: url("../images/wave.svg");
    background-repeat: no-repeat;
    display: inline-block;

    height: 7px;
    width: 22px;
    transition: .4s cubic-bezier(.49,1,.22,1) 0.7ms;
    &:after, &:before {
      content: "";
      background-image: url("../images/wave.svg");
      background-repeat: no-repeat;
      display: block;
      font-size: 20px;
      color: $white;
      font-weight: bold;

      height: 7px;
      width: 22px;
      transition: .4s cubic-bezier(.49,1,.22,1) 0.7ms;
    }
    &:before {
      transform: translateY(-7px);
    }
  }

  .navbar-toggler-text {
    @extend %advent-pro;
    color: $white;
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 0.5rem;
    display: flex;
  }

  &-open {
    .navbar-toggler-icon {
      background-image: none;
    }
    .navbar-toggler-icon:after {
      background-image: none;
      background-color: $white;
      width: 22px;
      height: 4px;
      content: "";
      transform: translate(0, 10px) rotate(-45deg);
    }
    .navbar-toggler-icon:before {
      background-image: none;
      background-color: $white;
      width: 22px;
      height: 4px;
      content: "";
      transform: translate(0px, 14px) rotate(45deg);
    }
    .navbar-toggler-text {
      opacity: 0;
    }
  }

  &:not(.navbar-toggler-open):hover {
    .navbar-toggler-icon {
      transform: translateX(-4px);
    }
    .navbar-toggler-icon:after {
      transform: translateX(8px);
    }
    .navbar-toggler-icon:before {
      transform: translate(8px, -7px);
    }
  }
}
.sidenav {
  height: 100%;
  width: 90vw;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: -100%;
  background-color: $bg-color-menu;
  overflow-x: hidden;
  transition: .7s cubic-bezier(.49,1,.22,1) 0ms;


  .sidenav-header {
    background-color: $grey-dark;
    height: 48px;
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    align-items: center;
    color: $text-color;
    font-size: 18px;
    z-index: 9;
    position: relative;

    .sidenav-control {
      a {
        color: #fff;
        font-size: 30px;
        text-decoration: none;
      }

      i {
        padding: 0 0.8rem;
        vertical-align: middle;
      }

      .closebtn {
        i {
          padding-right: 0;
          font-size: 16px;
        }
      }
    }
  }

  .sidenav-panels {
    position: relative;
    height: 100%;
  }
  .sidenav-panel {
    background-color: $bg-color-children;
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    width: 100%;
    transition: 0.5s;

    &_open {
      left: 0;
      &#panel-menu {
        left: -100%;
      }
    }

    .backbtn {
      background-color: darken($bg-color-menu,5%);
      color: $white;
      .custom-arrow {
        min-width: 20px;
        align-items: center;
        justify-content: start;
        &:before {
          border-color: $white;
          transform: rotate(-180deg);
        }
      }
    }

    a {
      padding: 14px 24px 14px 24px;
      display: flex;
      color: $body-color;
      text-decoration: none;
    }

    .btn {
      margin-top: 1rem;
      margin-left: 1.5rem;
      width: calc(100% - 3rem);
      text-align: center;
    }

    i {
      padding: 0 1rem;
      height: 51px;
      font-size: 18px;
      line-height: 51px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      &:first-child {
        background-color: $bg-color-parent;
      }

      li.has-subitems {
        position: relative;
        display: flex;

        a {
          flex: auto;
        }

        ul {
          display: none;
        }

        i {
          padding: 0 1rem;
          height: 51px;
          font-size: 18px;
          line-height: 51px;
        }

        .custom-arrow {
          min-width: 60px;
        }
      }

      li.highlight {
        > a {
          font-weight: bold;
          > i {
            display: inline-block;
            position: relative;
            margin-left: 6px;
            transform: rotate(-90deg);
            transition: 0.3s;
            font-size: 8px;
            line-height: 24px;
            vertical-align: middle;
            float: right;
          }
        }
      }

      li.label {
        padding: 8px 24px 8px 24px;

        + li {
          border-top: none;
        }
      }

      li {
        border-top: 1px solid #e7e7e7;
        &.label {
          padding-top: 24px;
          border-bottom: none;
        }
        a:hover {
          background-color: rgba(#ffffff,0.2);
        }
        ul {
          li {
            border-top: none;
            a {
              padding: 0 0;
            }
          }
        }
      }

      > a {
        display: none;
      }
      .submenu-intro {
        display: none;
      }
    }
  }
  #panel-menu {
    background-color: $bg-color-parent;
    left: 0;

    li {
      border-top: 1px solid rgba($bg-color-menu,0.25);
    }
    a:not(.btn) {
      padding: 14px 24px 14px 24px;
      text-decoration: none;
    }
  }
}

#page-wrapper.mobile-menu-active {
  &:before {
    opacity: 1;
    visibility: visible;
  }
  .sidenav {
    right: 0;
  }
}

.sidenav a {
  transition: 0.3s;
}

@include media-breakpoint-up(md) {

  .sidenav {
    width: 40vw;
  }
}
@include media-breakpoint-up(lg) {
  .navbar-toggler {
    margin-top: .875rem;
    margin-right: 0.5rem;
    width: auto;
    height: auto;
  }
}

@include media-breakpoint-up(xxl) {
  .navbar-toggler {
    margin-top: 1.8rem;
    margin-right: 1.8rem;
  }
}
.btn {
  display: inline-block;
  padding: 1.5rem 2rem;
  @include fluid-type($min_width, $max_width, (20px/1.4), 20px, (1.2), 1.2);
  color: $white;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  border: none;
  cursor: pointer;

  &-default {
    background-color: $grey-dark;

    &:hover {
      background-color: darken($grey-dark,5%);
      color: $white;
      text-decoration: none;
    }
  }

  &-primary {
    background-color: $primary;

    &:hover {
      background-color: darken($primary,5%);
      color: $white;
      text-decoration: none;
    }
  }

  &-secondary {
    background-color: $secondary;

    &:hover {
      background-color: darken($secondary,5%);
      color: $white;
      text-decoration: none;
    }
  }

  &-text {
    background-color: transparent;
    padding: .4rem 0;
    @extend %dagny-pro;
    @include fluid-type($min_width, $max_width, (18px/1.4), 18px, (1.2/1.4), 1.2);
    color: $primary;
    font-weight: normal;
    border-bottom: 1px solid!important;

    &.btn--arrow {
      &:after {
        border-left: 2px solid $red;
        border-bottom: 2px solid $red;
      }
    }

    &:hover {
      text-decoration: none;
    }
  }

  &-extend {
    display: block;
  }
}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}